@search-result-card-height: 60px;
@search-result-card-bg: @white;
@search-result-card-border-radius: 10px;

.card-delayed {
  position: relative;
  overflow: auto;
}

.card-delayed:before {
  display: block !important;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  background-color: @burnt-sienna;
  width: 3px;
  border-radius: 2px;
}

.search-result {
  &--header {
    text-align: left;
    font-size: @font-size-regular;
    color: @manatee;
    padding: 16px 24px;

    .action-header {
      cursor: pointer;
    }

    img {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }
  }

  &--row {
    display: flex !important;
    justify-content: left;
    overflow: hidden;
    text-align: left;
    font-size: @font-size-bold;
    margin-bottom: 8px;
    padding: 18px 24px;
    min-height: @search-result-card-height !important;
    height: auto !important;
    border-radius: @search-result-card-border-radius;
    background-color: @white;
    .box-shadow(0 2px 5px 0 rgba(0, 0, 0, 0.03));

    &:hover {
      background: @card-hover-bg;
      .box-shadow(0 2px 5px 0 rgba(0, 0, 0, 0.03));
      .border-radius(10px);
    }

    img {
      display: inline-block;
      margin-right: 8px;
    }

    .activity-title {
      font-family: @font-family-bold;
      font-size: @font-size-bold;
    }

    .task-progress {
      width: 100%;
      text-align: left;
      .ant-progress-outer {
        width: 80%;
      }
    }
  }
}
