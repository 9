.page-login {
  padding-bottom: 46px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span.platform-name {
  text-transform: uppercase;
  font-size: 35px;
  font-family: @font-family-bold;
  color: @white;
  margin-top: -44px;
}

.logo-wrapper {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-logo {
  > a {
    display: block;
  }

  width: 240px;
  height: 240px;
}

.error {
  font-size: @base-font-size;
  font-family: @font-family-regular;
  color: @burnt-sienna;
  height: 22px;
  line-height: 22px;
}

.btn-login:hover {
  background-color: @button-login-hover-bg;
}

.sign-in-button-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 16px;
  text-align: center;
  padding: 0;
  > button {
    span {
      font-family: @font-family-bold;
      font-size: @login-button-font-size;
      min-width: 340px;
    }
    box-sizing: border-box;
    color: @primary-color;
    text-align: center;
    line-height: 64px;
    border-radius: 5px;
    background-color: @white;
    height: 64px;
    &:hoverr {
      background-color: #e8ebf0;
    }
  }
}

.login-modal-window {
  .btn-rectangle {
    border-radius: 5px;
    width: 132px;
    height: 50px;
    font-size: 20px;
    
    &.ok-btn {
      margin-left: 15px;
    }
    &.cancle-btn,
    .cancle-btn:hover {
      color: #003168;
      border: 1.5px solid #003168;
    }
  }
  .ant-btn-default {
    color: @prussian-blue-hover;
  }
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-family: @font-family-bold;
      font-weight: bold;
      font-size: @font-size-bold;
    }
    .ant-modal-confirm-content {
      font-family: @font-family-regular;
      font-size: @font-size-regular;
    }
  }

  .ant-modal-confirm-btns {
    font-family: @font-family-bold;
    width: 100%;
    text-align: center;
  }

  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 24px;
    }
  }
}

.logout-menu {
  width: 221px;

  &.ant-dropdown-menu {
    margin-top: -4px;
    border-radius: 4px;
  }

  .ant-dropdown-menu-item {
    padding: 0px;
  }

  .underline {
    border: 0px solid #e8ebf0;
    border-bottom-width: 1px;
  }

  .ant-dropdown-menu-item:hover {
    background-color: transparent;
  }
}
