.add-checklist-modal {
  .ant-modal-header {
    border-bottom: none;
    padding-top: 24px;
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
}

.reject-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 488px;
    height: 288px;
  }
  .ant-modal-body {
    padding: 24px;
  }
}

.approve-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 350px;
    height: 238px;
    margin: 0 auto;
  }
  .ant-modal-body {
    padding: 24px;
  }
}

.urgent-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 488px;
    height: 391px;
  }
  .ant-modal-body {
    padding: 24px;
  }
}

.confirm-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 400px;
    height: auto;
  }
  .ant-modal-body {
    padding: 24px;
  }
}

.request-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 584px;
  }
}

.location-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 424px;
    margin: 0 auto;
  }
}

.accept-modal {
  .ant-modal-content {
    border-radius: 10px;
    width: 416px;
  }
}

.ant-modal {
  .circle-loading-progress {
    .ant-progress-text {
      color: #fff !important;
      font-size: 30px !important;
    }

    &.ant-progress-status-success .ant-progress-circle-path {
      stroke: #5bc79e !important;
    }
  }
}

.add-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 24px;
  }
}
