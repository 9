.btn__disabled {
  background: @mercury;
  color: @button-disabled-bg;
  outline: none;
  border-color: @mercury;
}

.btn-rectangle-lg {
  background: @prussian-blue;
  border-radius: 5px;
  height: 50px;
  color: @white;
  font-family: @font-family-bold;
  font-size: @font-size-bold;
  &:hover,
  &:active,
  &:focus {
    background: @rectangle-button-hover-bg;
    color: @white;
  }
}

.ant-btn-primary[disabled] {
  color: white;
  background-color: #e5e5e5;
}
