.breadcrumb {
  margin: 0;
  padding: 0;
  flex: 3;

  &-container {
    background-color: @white;
    color: @black;
    width: 75%;
    position: relative;
    nav {
      margin: 0 auto;
      width: 100%;
    }
  }

  &-body {
    width: auto;
    display: flex;

    ul {
      &.breadcrumb-list {
        padding: 0;
        font-family: @font-family-regular;
        font-size: @font-size-regular;
        list-style: none;

        &:after {
          content: '';
          height: 48px;
          display: block;
        }

        li {
          float: left;
          padding: 10px 0 10px 16px;

          &:first-child {
            padding-left: 0;
            img {
              width: 16px;
              height: 16px;
              margin-top: -3px;
            }
          }

          &:last-child {
            color: @manatee;
          }

          .active {
            text-decoration: none;
            color: @black;
            margin: 0 8px;
            &:after {
              box-sizing: border-box;
              content: url(/img/ic-right-arrow-black.svg);
              font-size: 18px;
              line-height: 18px;
              width: 15px;
              height: 16px;
              margin: 0 8px;
              position: absolute;
              top: 15px;
              display: inline-block;
            }
          }

          .inactive {
            text-decoration: none;
            color: @black;
            margin: 0 8px;
            &:after {
              box-sizing: border-box;
              content: url(/img/right-arrow.svg);
              font-size: 18px;
              line-height: 18px;
              width: 15px;
              height: 16px;
              margin: 0 8px;
              position: absolute;
              top: 15px;
              display: inline-block;
              padding-right: 15px;
            }
          }

          &:last-of-type {
            a {
              &:after {
                border: none;
                color: @manatee;
              }
            }
          }

          span {
            padding-left: 8px;
          }
        }
      }
    }
  }
}
