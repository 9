@pureto-rico: #5bc79e;
@saffron: #efc122;
@burnt-sienna: #d0494c;
@mercury: #e5e5e5;
@bluey-grey: #949aa1;
@prussian-blue: #003168;
@black: #000000;
@white: #ffffff;
@manatee: #8b99a7;
@athens-gray: #f3f5f8;
@prussian-blue-hover: #003168;
@button-hover: #184b85;
@grey: #cccccc;
@grey-bg: #d8d8d8;
@soft-grey: #d9d9d9;
@soft-cream: #ececec;
@base-border-color: #e4e7eb;
@activity-history-border-bottom: #eeeff0;
@card-hover-bg: #e8ebf0;
@button-hover-bg: #e8ebf0;
@button-disabled-bg: #b0b1b2;
@button-filter-hover-bg: #e8ebf0;
@button-login-hover-bg: #e8ebf0;
@button-filter-active-bg: #dce0e7;
@round-button-hover-bg: #184b85;
@rectangle-button-hover-bg: #184b85;

// Filters
.filters {
  .ant-popover-open {
    background: @button-filter-active-bg;
    border-color: @button-filter-active-bg;
    color: @black;
  }

  .ant-btn {
    .border-radius(20px);
    color: @black;
    font-size: 18px;

    &:hover {
      background: @button-filter-hover-bg;
      border-color: @button-filter-hover-bg;
      color: @black;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus {
      background: @button-filter-active-bg;
      border-color: @button-filter-active-bg;
      color: @black;
    }
  }

  .ant-btn-primary {
    background: @prussian-blue;
    border-color: @prussian-blue;
    color: @white;

    &:hover {
      background: @round-button-hover-bg;
      border-color: @round-button-hover-bg;
      color: @white;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus {
      background: @prussian-blue-hover;
      border-color: @prussian-blue-hover;
      color: @white;
    }
  }
}

// for status
@danger: @burnt-sienna;
@warning: @saffron;
@success: @pureto-rico;
@todo: @bluey-grey;
@normal: @black;

// Tables
@table-header-bg: @white;

.color {
  &-default {
    color: @todo;
  }
  &-init {
    color: @todo;
  }
  &-appv {
    color: @success;
  }
  &-pend {
    color: @warning;
  }
  &-inpro {
    color: @warning;
  }
  &-rejt {
    color: @danger;
  }
  &-delayed {
    color: @danger;
  }
}
