// Ant overrides will always be at top
.ant-progress-text {
  color: rgba(0, 0, 0, 1) !important;
  font-size: @font-size-bold !important;
}

.task {
  &-todo {
    .ant-progress-text {
      color: rgba(0, 0, 0, 0.5) !important;
      font-size: @font-size-bold;
    }
  }
  &-progress {
    text-align: center;
  }
}

// Component specific
.tasklane {
  min-width: 368px;
  &-wrapper {
    margin-bottom: 40px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: @font-family-bold;
      font-size: @tasklane-title-font-size;
      color: black;
    }
    .action {
      font-size: @font-size-regular;
      color: @manatee;
    }

    .detail {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .btn--seemore {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    font-family: @font-family-bold;
    .border-radius(10px);
    font-weight: bold;
    font-size: 18px;
    .box-shadow(@base-box-shadow);
    background-color: white;
    color: @black;
    &:hover {
      background-color: @button-hover-bg;
    }
  }

  &__norecord {
    .card-layout;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: @base-font-size;
    padding-left: 16px;
  }
  &__loading {
    .card-layout;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: @base-font-size;
    text-align: center;
  }
}

.card-layout {
  .border-radius(10px);
  .box-shadow(@base-box-shadow);
  background: @white;
}

.activity-title {
  line-height: 30px;
  padding: 0;
  margin: 0;
}

.wbs {
  margin: 0;
  padding: 0;
}

.lane-card {
  background: @white;
  .border-radius(10px);
  .box-shadow(@base-box-shadow);
  margin-bottom: 12px;
  padding: 16px;
  min-width: 318px;
  cursor: pointer;
  color: @black;
  h2,
  .date {
    .font-regular();
  }
  &-delayed {
    content: '';
    display: block;
    background: @burnt-sienna;
    padding-left: 4px;
    margin-left: 0px;
    top: 1px;
    left: 0px;
    width: 3px;
    height: 99%;
    position: absolute;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &-success {
    content: '';
    display: block;
    background: #5bc79e;
    padding-left: 4px;
    margin-left: 0px;
    top: 1px;
    left: 0px;
    width: 3px;
    height: 99%;
    position: absolute;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:hover {
    background: @card-hover-bg;
  }
}

.hide-label {
  visibility: hidden;
}

.end-date {
  margin-left: 16px;
}

.status {
  font-size: @base-font-size;
  margin-bottom: 4px;

  i {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 2px;
    margin-right: 4px;
  }

  &-danger {
    color: @danger;
  }
  &-warning {
    color: @warning;
  }
  &-default {
    color: @manatee;
  }
}

.task-progress {
  flex-direction: column;
  display: flex;
  &-label {
    margin-top: 7px;
  }

  &-left {
    width: 70% !important;
    margin-right: auto;
  }
  &-right {
    width: 20% !important;
  }
  &-label {
    font-size: @base-font-size;
    width: auto;
    display: block;
    text-align: center;
  }
  &-info {
    width: 78%;
  }
}

.status-danger {
  img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
  }
}

// Show the progressive render
.info-loading .countdown,
.info-loading .activity-title,
.info-loading .wbs,
.info-loading .task-progress,
.info-loading .status,
.info-loading .start-date,
.info-loading .end-date {
  background: #eee;
  color: #eee;
}

.info-loading span.ant-progress-text,
.info-loading .status-danger img,
.info-loading .ant-progress-circle,
.info-loading .task-progress-label {
  opacity: 0;
}

.info-loading .lane-card-delayed {
  background: #000;
  opacity: 0.2;
}
