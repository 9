.sub-nav {
  height: 48px;
}

.subnavbar {
  background-color: @white;
  &-content {
    display: flex;
    background: @white;
    margin: 0 80px;
    flex-direction: row;
  }
}

.search-box {
  flex: 1;
  text-align: right;
  height: 48px;
  line-height: 18px;
  font-size: 18px;
  font-family: @font-family-regular;
  width: 120px;
  display: flex;
  justify-content: space-between;
  border-left: 1px solid @soft-cream;

  input {
    outline: none;
    border: none;
    padding: 13px 20px 8px 23px;
    font-family: @font-family-regular;
    font-size: 18px;
    color: black;
    width: 100%;
    float: left;

    &:hover,
    &:active,
    &:focus {
      content: '';
      border: none;
      padding-right: 20px;
    }
    &::placeholder {
      color: #8b99a7;
    }
  }
}

.btn-search {
  border: 1px solid @white;
  cursor: pointer;
  float: right;
  height: 100%;
}
