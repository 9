i {
  &.logo {
    display: inline-block;
    content: '';
    height: 32px;
    width: 32px;
    .border-radius(50%);
    align-content: center;
    background: @grey-bg;
  }
}

.header-rules() {
  height: @header-height;
  background-color: @primary-color;
  color: @white;
}

.top-nav {
  width: 100%;
  height: 64px;
  .nav-wrapper {
    width: 100%;
  }

  &-wrapper {
    .header-rules();
    width: 100%;
  }

  .nav-right {
    width: 170px;
    padding-left: 10px;
    float: right;
  }

  .nav-left {
    flex-direction: row;
    position: relative;
    display: flex;
    i {
      &.logo {
        margin-top: 16px;
      }
    }

    .intro {
      min-width: 140px;

      a {
        margin-left: 8px;
        text-transform: uppercase;
        color: @white;
        font-size: 24px;
      }

      a > img {
        margin-right: 20px;
        padding-bottom: 3px;
      }

      a > span {
        min-width: 150px;
      }
    }

    .intro:after {
      content: '';
      width: 1px;
      height: 16px;
      border: 1px solid @white;
      margin: 0 0 0 24px;
      opacity: 0.25;
      display: inline-block;
    }

    .project-selected {
      font-family: @font-family-bold;
      font-size: @font-size-regular;
    }

    .projects {
      &-list-wrapper {
        position: relative;
        line-height: 62px;
        z-index: 10;
      }
      &-list-dropdown {
        .box-sizing();
        z-index: 10;
        width: 420px;
        position: relative;
        height: auto;
        background: @primary-color;
        span {
          font-family: @font-family-regular;
          font-size: 24px;
          font-weight: normal;
          letter-spacing: normal;
          margin-left: 24px;
          img {
            margin-left: 24px;
            position: relative;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: @prussian-blue-hover;
        }
      }

      &-list-options {
        font-size: @font-size-regular;
        margin-bottom: -10px;
        .box-shadow(0px 1px 4px 0px @grey);
        border-top: none;
        z-index: 4;
        position: absolute;
        width: 100%;
        left: -50px;
        border-radius: 5px;
        background: @white;
        padding: 16px 24px 8px 24px;
        height: auto;
        max-height: 420px;
        overflow-y: scroll;

        div {
          color: @black;
          line-height: initial;
          height: 40px;
          &:hover {
            cursor: pointer;
            color: @prussian-blue-hover;
          }
        }

        &.active {
          display: block;
        }

        &.in-active {
          transform: translateY(-270px);
          z-index: 1;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .profile-section {
    // overflow: auto;
    padding: 16px 0;
    cursor: pointer;
    position: relative;
    div {
      &.profile-info {
        display: flex;
        // overflow: auto;
        flex-flow: column;
        line-height: 15px;
        font-size: @font-size-regular;
        font-family: @font-family-regular;
        padding-left: 55px;
        span {
          &:nth-child(1) {
            font-weight: bold;
          }
        }
        .name {
          margin-bottom: 2px;
        }
      }
    }

    .menubar {
      z-index: 999;
      padding: 10px;
      position: absolute;
      top: 64px;
      color: @black;
      font-family: @font-family-regular;
      font-size: @font-size-regular;
      line-height: 30px;
      width: 100%;
      height: 56px;

      border-radius: 5px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.07);
      background-color: @white;
    }

    .profile-img {
      position: absolute;
      display: inline-block;
      top: 16px;
      left: 0;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: solid 1px #979797;
      background-color: #d8d8d8;
    }

    .icon {
      position: absolute;
      display: inline-block;
      top: 35%;
      right: 0;
      vertical-align: middle;
    }
  }
}

i {
  &.icon-checked:after {
    content: '';
    top: 4px;
    left: 3px;
    width: 9px;
    height: 6px;
    position: absolute;
    border-bottom: 2px solid @white;
    border-left: 2px solid @white;
    .transform(rotate(-45deg));
  }

  &.icon-checked {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: @pureto-rico;
    float: right;
    margin-top: 1px;
    border-radius: 50%;
    position: relative;
  }
}
