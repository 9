/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 18, 2019 */
@font-face {
  font-family: 'DB Helvethaica X 75 Bd';
  src: url('db_helvethaica_x_bd_v3.2.woff2') format('woff2'),
    url('db_helvethaica_x_bd_v3.2.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'DB Helvethaica X 55 Regular';
  src: url('db_helvethaica_x_v3.2.woff2') format('woff2'),
    url('db_helvethaica_x_v3.2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DB Komol Regular';
  src: url('DB Komol X.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DB Komol Bd';
  src: url('DB Komol X Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
