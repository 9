.editor-view {
  top: 0px !important;
  height: 100vh;
}

.editor-view > .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.editor-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}
