.std-font-regular {
  font-family: @font-family-regular;
}
.std-font-bold {
  font-family: @font-family-bold;
}

.font-regular() {
  font-family: @font-family-regular;
  font-size: @font-size-regular;
}
.font-bold() {
  font-family: @font-family-bold;
  font-weight: bold;
}

.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}
.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}

.pointer {
  :hover {
    cursor: pointer;
  }
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;

  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;

  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

// .gradient (@startColor: #eee, @endColor: white) {
// 	background-color: @startColor;
// 	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
// 	background: -webkit-linear-gradient(top, @startColor, @endColor);
// 	background: -moz-linear-gradient(top, @startColor, @endColor);
// 	background: -ms-linear-gradient(top, @startColor, @endColor);
// 	background: -o-linear-gradient(top, @startColor, @endColor);
// }
// .horizontal-gradient (@startColor: #eee, @endColor: white) {
//  	background-color: @startColor;
// 	background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
// 	background-image: -webkit-linear-gradient(left, @startColor, @endColor);
// 	background-image: -moz-linear-gradient(left, @startColor, @endColor);
// 	background-image: -ms-linear-gradient(left, @startColor, @endColor);
// 	background-image: -o-linear-gradient(left, @startColor, @endColor);
// }

// .animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
// 	-webkit-animation: @name @duration @delay @ease;
// 	-moz-animation:    @name @duration @delay @ease;
// 	-ms-animation:     @name @duration @delay @ease;
// }

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
}
.transform(@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
}
// .scale (@factor) {
// 	-webkit-transform: scale(@factor);
// 	-moz-transform: 	 scale(@factor);
// 	-ms-transform: 		 scale(@factor);
// 	-o-transform: 		 scale(@factor);
// }
.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
}

.user-select() {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.make-round-button(@width, @height, @color, @border: @button-width-base) {
  content: '';
  display: block;
  width: @width;
  height: @width;
  background-color: @color;
  border: @border solid @color;
  border-radius: 50%;
}

.make-check-mark(@width, @height, @color, @border: @button-width-base) {
  content: '';
  display: block;
  width: @width;
  height: @height;
  border-top: none;
  border-right: none;
  border-left: @border @button-fill-base @white;
  border-bottom: @button-width-base @button-fill-base @white;
}

.message-box {
  background-color: rgba(0, 0, 0, 0.5);
}

.error-box {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  .ant-modal-content {
    width: 200px;
    height: 180px;
    background-color: rgba(14, 21, 32, 0.75);
    margin: 0 auto;
    border-radius: 10px;
    font-family: 'DB Helvethaica X 55 Regular';
  }
}

.ant-tooltip {
  z-index: 100 !important;
}
