@font-family-bold: 'DB Helvethaica X 75 Bd';
@font-family-regular: 'DB Helvethaica X 55 Regular';

@base-font-size: 18px;
@primary-font-size: 28px;
@font-size-regular: 18px;
@font-size-bold: 20px;
@tasklane-title-font-size: 24px;
@login-button-font-size: 24px;

@font-weight-bold: 900;

h1 {
  font-family: @font-family-bold;
  font-size: @primary-font-size;
}

.form {
  &-label {
    font-family: @font-family-bold;
    font-size: @font-size-bold;
    margin-bottom: 8px;

    span {
      font-family: @font-family-regular;
      font-size: 16px;
    }
  }
}
