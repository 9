@import '~antd/dist/antd.less';

@import './colors.less';
@import (css) './fonts/stylesheet.css';
@import './mixin.less';
@import './types.less';
@import './variables.less';
@import './antd-overrides.less';

#app {
  height: 100%;
  font-family: @font-family-regular;
  font-size: @primary-font-size;
}

@import './button.less';
@import './logo.less';
@import './login.less';
@import './menu.less';
@import './breadcrumb.less';
@import './subnavbar.less';
@import './content.less';
@import './filters.less';
@import './tasklanes.less';
@import './activity-details.less';
@import './datagrid.less';
@import './search-result.less';

@import './footer.less';
@import './default.less';
@import './document-view.less';
@import './editor-view.less';
@import './drawer.less';
@import './modal.less';
@import './dropdown.less';
@import './date-picker.less';
