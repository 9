@datagrid-prefix: datagrid;

.ant-table-header-column {
  font-size: @font-size-bold !important;
}

th:first-child {
  padding-left: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td:first-child {
  padding-left: 0;
}

.ant-table-thead > tr > th {
  background-color: #fff;
}

.ant-table-thead > tr,
.ant-table-tbody > tr {
  font-size: 20px;
}
