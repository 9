@pagination-button-base-width: 32px;
@pagination-button-base-height: 32px;
@pagination-button-base-border-radius: 5px;

.box-styles() {
  .border-radius(10px);
  .box-shadow(0 6px 10px 0 rgba(0, 0, 0, 0.03));
  padding: 24px;
  background: @white;
}

.mid-content {
  background-color: @athens-gray;
  color: @black;
  height: 100%;
  overflow: auto;
}

.content {
  width: @content-width;
  margin: @content-margin;
}

.main-content {
  margin: 0 80px;
}

.search-result-page,
.see-more-page {
  margin-top: 32px;
  .page-title {
    height: 30px;
    display: inline-block;
    margin-left: 10px;
    font-family: @font-family-bold;
    font-size: 28px;
    color: black;
    .result-counts {
      font-size: 24px;
      margin-right: 8px;
      font-family: @font-family-regular;
    }
  }
}

.see-more-page {
  margin-top: 0;
}

.content-header {
  display: flex !important;
  margin-bottom: 24px;

  .page-title {
    display: flex;
    width: 50%;
    margin: 0;
  }
}

.content-footer {
  display: flex !important;
  margin-top: 40px;
  margin-bottom: 49px;
}

.pagination-show-action {
  font-size: @font-size-regular;
  font-family: @font-family-regular;
  display: flex !important;
  align-items: center;
  width: 50%;
  .ant-select-selection-selected-value {
    font-family: @font-family-regular !important;
    font-size: @base-font-size !important;
    margin-left: 24px;
  }

  .ant-select-selection {
    width: 94px;
    height: 40px;
    padding-top: 5px;
    line-height: 40px;
    margin: 0 16px;
    border-radius: 20px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }
}

.pagination-wrapper {
  width: 50%;
  text-align: right;
  .pagination-items {
    list-style: none;
  }
  .pagination-block {
    list-style: none;
    float: right;
    margin: 0;
    li {
      float: left;
      line-height: @pagination-button-base-height;
      margin: 0 4px;
      font-size: @base-font-size;
    }
  }

  .active-page {
    background: @prussian-blue-hover;
    color: @white;
    font-family: @font-family-bold;
  }

  .pagination {
    &-icon {
      cursor: pointer;
    }

    &-number {
      cursor: pointer;
      user-select: none;
      display: block;
      width: @pagination-button-base-width;
      height: @pagination-button-base-height;
      text-align: center;
      border-radius: @pagination-button-base-border-radius;
      font-family: @font-family-regular;
    }
  }
}

.loading-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty-project-list-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.detail-page-footer {
  background-color: @white;
  margin: 0;
  height: 96px;
  box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.04);
  z-index: 1;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    // -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-inside: always;
  }
}
