.document-view {
  top: 0px !important;
  height: 100vh;
}

.document-view > .ant-modal-content {
  background-color: transparent;
}

.document-view > .ant-modal-content > button {
  color: white;
}

.document-view > .ant-modal-content > .ant-modal-body {
  padding: 60px;
}
