.search-dropdown {
  &.ant-dropdown-menu {
    margin: -4px 12px;
    border-radius: 5px;
  }
}

.dropdown-list > .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}

.dropdown-list > .ant-dropdown-menu > .ant-dropdown-menu-item {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dropdown-list > .ant-dropdown-menu > .ant-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}

.ant-dropdown-menu {
  border-radius: 5px !important;
  border: solid 1px #eeeff2 !important;;
  &.config-header,
  &.dropdown-search,
  &.dropdown-list-main {
    border-radius: 5px;
    border: solid 1px #eeeff2;
  }
}
