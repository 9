.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  background: @mercury;
  color: @button-disabled-bg;
  outline: none;
  border-color: @mercury;
}

.ant-btn[disabled] {
  background: @mercury;
  border-color: @mercury;
}

.ant-menu-horizontal {
  & {
    color: @white;
  }
}

.ant-layout-header {
  .header-rules();
  display: flex;
  padding: 0 80px;
  ul {
    &.ant-menu {
      background-color: @primary-color;
      color: @white;
      height: @header-height;
    }
  }
}

.ant-layout {
  height: 100%;
  background-color: #f5f6fa;
}

.ant-layout-footer {
  height: 50px;
  margin-bottom: 10px;
  background-color: @primary-color;
  color: @white;
}

.ant-layout-content {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-row {
  margin-right: auto;
  margin-left: auto;
}

/* Generate for antd css - popover */
/* @see https://github.com/ant-design/ant-design/blob/master/components/popover/style/index.less#L179 */
.ant-popover {
  &-placement-bottomLeft > &-content > &-arrow {
    left: 50px !important;
  }
  &-inner-content {
    padding: 20px 40px 24px 40px !important;
  }
}

.ant-progress-circle {
  .ant-progress-text {
    font-family: @font-family-bold;
  }
}

/** Modal Container **/
.modal {
  &-content {
    .font-regular();
  }
}
.ant-modal-body {
  padding: 32px;
}

.ant-modal-content {
  border-radius: 10px;
}

.datagrid-wrapper .ant-table-tbody > tr > td,
.ant-table-thead > tr > th:not(:first-child) {
  padding: 16px 16px 16px 0;
  vertical-align: top;
}

.activity-history-modal-container h1.title {
  margin-bottom: 28px;
  height: 30px;
}

.activity-history-modal-wrapper .ant-modal-body {
  padding: 32px !important;
  border-radius: 10px;
}

.activity-history-modal-wrapper .ant-modal-close {
  padding-top: 32px;
  padding-right: 32px;
}

.activity-history-modal-wrapper .ant-modal-close-x {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}

.activity-history-modal-wrapper > .ant-modal > .ant-modal-content {
  border-radius: 10px;
}

.activity-history-modal-container .modal-content {
  height: 400px;
  border-radius: 10px;
  overflow-y: auto;
}

.ant-table-thead tr > th {
  padding: 0 !important;
  margin: 0 !important;
}

.datagrid-wrapper table thead > tr > th {
  padding-bottom: 8px !important;
}
