@checkbox-height: @checkbox-base-height;
@checkbox-width: @checkbox-base-width;
@checkbox-normal-bg-color: @base-border-color;
@checkbox-checked-bg-color: @pureto-rico;
@checkbox-checked-mark-color: @white;

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 16px;
  margin-right: 12px;
}

.ant-calendar {
  .font-regular() !important;
  border-radius: 5px !important;
  .ant-calendar-ok-btn {
    border-radius: 5px !important;
  }
  .ant-calendar-ok-btn-disabled {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .ant-calendar-selected-day .ant-calendar-date {
    background: #bae7ff;
  }
}

.filters {
  overflow: auto;
  button {
    .font-regular();
    margin-right: 8px;
    border: 2px solid @white;
    padding-left: 24px;
    padding-right: 24px;
    .box-shadow(0 6px 10px 0 rgba(29, 23, 23, 0.03));

    span {
      &.icon-down {
        padding-left: 16px;
      }
    }
  }
}

.filter-clear {
  float: right;
  color: #003168;
  font-size: 18px;
}

.filter-clear:hover {
  cursor: pointer;
}

.category-row {
  margin-bottom: 16px;
}

.popover-form,
.status-form {
  position: relative;
  &-container {
    overflow: auto;
    .title {
      float: left;
      font-weight: bold;
    }
    .action {
      float: right;
      color: @prussian-blue-hover;
    }

    .progress {
      &-row {
        display: flex !important;
        align-items: center;
        height: 40px !important;
      }
      &-checkbox-wrapper {
        span {
          &.label {
            padding-left: 16px;
          }
        }
      }
    }
  }
}

.pop-over-container {
  font-size: 18px;
  //style={{ textAlign: 'right' }}
  .ss-pop-over-action {
    font-family: @font-family-regular;
    font-size: 16px;
    text-align: right;
  }
  .title {
    &-20 {
      .font-bold();
      font-size: 20px;
    }
  }
  &-choices {
    .has-child {
      padding-left: 20px;
      &:first-child {
        padding: 0;
      }
    }

    input {
      &[type='checkbox'] {
        display: inline-block;
        line-height: 1;
        visibility: hidden;
        font-size: 18px;
        width: 21px;
        margin-top: 3px;
        height: 21px;
      }
    }

    input[type='checkbox']:before {
      content: ' ';
      border: 2px solid @checkbox-normal-bg-color;
      width: @checkbox-width;
      height: @checkbox-height;
      border-radius: 50%;
      visibility: visible;
      display: inline-block;
      background: transparent;
    }

    input[type='checkbox']:checked:before {
      position: relative;
      content: ' ';
      border: 2px solid @checkbox-checked-bg-color;
      background: @checkbox-checked-bg-color;
      width: @checkbox-width;
      height: @checkbox-height;
      border-radius: 50%;
      visibility: visible;
      display: inline-block;
    }

    .checkbox-container {
      position: relative;
      min-height: 40px;
      font-size: 18px;
      span {
        font-size: 18px;
        font-family: @font-family-regular;
        cursor: pointer;
      }
    }

    input[type='checkbox']:checked:after {
      position: absolute;
      top: 8px;
      left: 4px;
      width: 9px;
      height: 6px;
      content: ' ';
      border-left: 2px solid @checkbox-checked-mark-color;
      border-bottom: 2px solid @checkbox-checked-mark-color;
      transform: rotate(-55deg);
      visibility: visible;
      display: inline-block;
    }
  }
}

.category-choice {
  display: block;
  width: 100%;
  padding: 10px 0;
}

/* Collapse */
.ant-collapse {
  &-header {
    padding: 0 !important;
  }
  &-content-box {
    padding-right: 0 !important;
  }

  &-item-active {
    span.chk-icon img {
    }
  }
}

.filter-button-border() {
  border: solid 2px @base-border-color;
}

.button-group {
  button {
    .border-radius(5px);
    width: 78px;
    height: 40px;
    margin-right: 8px;
    .filter-button-border();
  }
}

.input-with-icon {
  box-sizing: border-box;
  border: solid 1px @base-border-color;
  font-family: @font-family-regular;
  font-size: 18px;
  .border-radius(5px);
  padding: 16px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  user-select: none;
  input {
    font-family: @font-family-regular;
    font-size: 20px;
    font-weight: bold;
    outline: none !important;
    border: none;
    text-align: right;
    padding-right: 45px;
    color: #000;
  }
  .label {
    position: relative;
    flex: 2;
  }
}

.input-with-icon-disabled {
  background-color: #e4e7eb;
  color: black;
  opacity: 0.3;

  .ant-calendar-picker > div > .ant-input-disabled {
    background-color: #e4e7eb;
    color: black;
    font-family: @font-family-bold;
  }

  .label {
    position: relative;
    flex: 2;
  }
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.input-datetime {
  display: flex;
  align-items: center;
  font-family: @font-family-regular;
  font-size: 18px;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;

  .label {
    position: relative;
    flex: 2;
    margin-left: 16px;
    color: black;
  }

  input {
    font-family: @font-family-regular;
    font-size: 20px;
    font-weight: bold;
    outline: none !important;
    border: none;
    text-align: right;
    padding-right: 45px;
    color: black;
  }

  input::placeholder {
    font-size: 18px;
    font-weight: normal;
  }
}

.filter-range-btn {
  width: 78px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  color: black;
  margin-right: 10px;
  font-size: 16px;
  font-family: @font-family-regular;
}

.filter-range-btn:hover {
  border: solid 2px #003168;
}

.filter-range-btn-action {
  border: solid 2px #003168 !important;
}

.filters-button {
  padding-top: 32px;
  padding-bottom: 32px;
}

.filters-button > .btn-active {
  background-color: #dce0e7;
  border-color: #dce0e7;
}

.filter-button-container {
  padding-left: 80px;
  padding-right: 80px;
  background: @athens-gray;
}

.ant-popover-inner-content {
  padding: 0px !important;
  max-height: 360px;
  overflow: scroll;
}

.popup-menu-checkbox {
  .ant-checkbox {
    input {
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }
  }

  span {
    font-size: 18px;
    font-family: @font-family-regular;
  }
}

.pop-over-container-choices > .ant-col > .ant-collapse-borderless {
  background-color: white;
}
