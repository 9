// Ant Select overrides
@ant-prefix: 'ant';
@select-border-color: @base-border-color;
@select-item-selected-font-weight: 900;
@input-prefix-cls: ~'@{ant-prefix}-input';

.ant-select {
  &-lg {
    font-size: @font-size-bold;
  }

  // Remove Blue border around the button
  &-focused &-selection,
  &-selection:focus,
  &-selection:active {
    .box-shadow(none) !important;
  }

  &-selection {
    .user-select();
    border: none !important;
    float: right;

    &-selected-value {
      font-family: @font-family-bold;
      font-size: @font-size-bold;
      font-weight: bold;
      text-align: right;
    }
  }
}

// Ant Input overrides, Remove Blue border around the button
.@{input-prefix-cls} {
  &:focus {
    .box-shadow(none) !important;
  }
}

.ant-select-dropdown {
  &-menu {
    font-size: 20px;
    font-weight: bold;
    background-color: @white;
    color: @black;
    border: none;
    outline: none;
    box-shadow: none;
    &-item {
      line-height: 40px;
      font-size: 18px;
      font-family: @font-family-regular;
      padding: 5px 16px !important;
    }
    &-item-selected {
      background-color: @white !important;
      color: @black !important;
      &:before {
        .make-round-button(16px, 16px, @pureto-rico);
        position: absolute;
        top: 8px;
        right: 16px;
      }
      &:after {
        .make-check-mark(9px, 5px, @white);
        position: absolute;
        top: 13px;
        right: 16px + 3px;
        transform: rotate(-45deg);
      }
    }
  }
}

// Activity Detail Page Specifics here
.activity {
  &-detail {
    width: 1110px;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
    .header {
      .action {
        font-size: 16px;
        color: @prussian-blue-hover;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
    &-content {
      width: @content-width-fix;
      margin: 0 auto;
    }
  }

  &-info {
    .box-styles();
    text-align: center;
    &-row {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      font-size: @font-size-bold;
      .label {
        .font-bold();
      }
      .value {
        text-align: right;
        .font-regular();
      }
    }
  }

  &-form {
    .box-styles();
  }
}

.btn {
  &--submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    font-family: @font-family-bold;
    font-size: @font-size-bold;
    margin-left: auto;
    height: @button-height-base;
    background-color: @prussian-blue-hover;
    color: @white;

    .border-radius(@button-radius-base);
  }
}

.form-actions {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: @font-family-bold;
    font-size: @primary-font-size;
    color: black;
  }
  .action {
    font-size: @font-size-regular;
    color: @manatee;
    &.anticon {
      margin-right: 8px;
    }
  }
}

.datagrid-wrapper .ant-table-column-title {
  .font-regular();
  color: @manatee;
}
.datagrid-wrapper .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 0;
}
.datagrid-wrapper table thead > tr > th {
  background-color: @table-header-bg;
  border-bottom: 0;
}
.datagrid-wrapper .ant-table-tbody tr:hover:not(.ant-table-expanded-row) > td {
  background-color: @athens-gray;
}
.datagrid-wrapper .ant-table-tbody > tr > td {
  font-family: @font-family-regular;
  &:first-child {
    .font-bold();
  }
}

.task-progress-reject > .ant-progress-inner > svg > path:nth-child(2) {
  stroke: #d0494c !important;
}

.task-progress-pending > .ant-progress-inner > svg > path:nth-child(2) {
  stroke: #efc122 !important;
}

.task-progress-reject > .ant-progress-inner > svg > path:nth-child(3) {
  stroke: #5bc79e !important;
}

.task-progress-pending > .ant-progress-inner > svg > path:nth-child(3) {
  stroke: #5bc79e !important;
}

.task-progress-reject
  > div
  > .ant-progress-outer
  > .ant-progress-inner
  > .ant-progress-bg {
  background-image: linear-gradient(to right, #d0494c, #d0494c) !important;
}

.task-progress-reject
  > div
  > .ant-progress-outer
  > .ant-progress-inner
  > .ant-progress-success-bg {
  background-color: #5bc79e !important;
}

.task-progress-pending
  > div
  > .ant-progress-outer
  > .ant-progress-inner
  > .ant-progress-bg {
  background-image: linear-gradient(to right, #efc122, #efc122) !important;
}

.task-progress-pending
  > div
  > .ant-progress-outer
  > .ant-progress-inner
  > .ant-progress-success-bg {
  background-color: #5bc79e !important;
}

.progress-select
  > .ant-select-selection
  > div
  > .ant-select-selection-selected-value {
  padding-right: 16px;
  color: black;
}

.tools-popup-menu > .ant-popover-content > .ant-popover-arrow {
  border-right-color: #4e4f50 !important;
  border-bottom-color: #4e4f50 !important;
}
