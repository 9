.drawer-project {
  width: 0px !important;
  z-index: 100 !important;
}
.drawer-project > .ant-drawer-mask {
  width: 0px !important;
}

.drawer-project > .ant-drawer-content-wrapper {
  margin-top: 113px;
  box-shadow: 0 0 30px 0 rgba(0, 49, 104, 0.1) !important;
  .ant-drawer-body {
    margin-bottom: 100px;
  }
}

.drawer-project
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header-no-title {
  height: 40px;
}
